import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ModalService } from '@app/shared/component/dialog/abstract';
import { ConfirmedForm } from '@typings';

import { FormConfirmSaveComponent } from './component/form-confirm-save.component';
import { FormConfirmOptions, FormConfirmSaveAbstract } from './abstract';

@Injectable({
  providedIn: 'root',
})
export class FormConfirmSaveService implements FormConfirmSaveAbstract {
  private isModalAlreadyOpen = false;

  forms: ConfirmedForm[] = [];

  constructor(private modal: ModalService) {}

  setForm(form: UntypedFormGroup, options?: Partial<FormConfirmOptions>, onUnmount?: () => void) {
    this.forms.push({
      form: form,
      options: options || undefined,
      onUnmount: onUnmount || undefined,
      initialValue: JSON.stringify(form.value),
    });
  }

  popForm(): void {
    this.forms.pop();
  }

  closeForm(shouldConfirm: boolean = true): Observable<boolean> {
    let result = of(true);

    const formsLength = this.forms.length;

    if (!formsLength) {
      return result;
    }

    const topForm = this.forms[formsLength - 1];

    const unmountForm = () => {
      this.popForm();

      if (topForm.onUnmount) {
        topForm.onUnmount();
      }
    };

    if ((shouldConfirm && this.isFormChanged(topForm)) || (topForm.options?.checkIfTouched && topForm.form.touched)) {
      result = this.onConfirm(topForm).pipe(tap((r) => r && unmountForm()));
    } else {
      unmountForm();
    }

    return result;
  }

  closeForms(shouldConfirm: boolean = true): Observable<boolean> {
    let result = of(true);

    const formsLength = this.forms.length;

    if (!formsLength) {
      return result;
    }

    let changes = this.forms.map((f) => this.isFormChanged(f));
    const topForm = this.forms[formsLength - 1];

    if (shouldConfirm && changes.includes(true)) {
      result = this.onConfirm(topForm).pipe(tap((r) => r && this.unmountForm(topForm)));
      this.unmountForm(topForm);
    } else {
      this.unmountForm(topForm);
    }

    return result;
  }

  unmountForm = (topForm: ConfirmedForm) => {
    this.popForm();

    if (topForm.onUnmount) {
      topForm.onUnmount();
    }
  };

  isFormChanged(form: ConfirmedForm): boolean {
    return form.initialValue !== JSON.stringify(form.form.value);
  }

  isLastFormChanged(): boolean {
    const form = this.forms[this.forms.length - 1];
    return this.isFormChanged(form);
  }

  onConfirm(form: ConfirmedForm): Observable<boolean> {
    if (this.isModalAlreadyOpen) {
      return of(false);
    }

    if (!form) {
      throw Error('You sholuld set form before used FormConfirmSaveService methods');
    }

    this.isModalAlreadyOpen = true;
    return this.openDialog(form);
  }

  openDialog(form?: ConfirmedForm): Observable<boolean> {
    return this.modal
      .openDialog(FormConfirmSaveComponent, {
        data: form?.options || null,
        autoFocus: false,
      })
      .afterClosed()
      .pipe(
        map((r) => Boolean(r)),
        tap((_) => {
          this.isModalAlreadyOpen = false;
        }),
      );
  }
}
