import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, of } from 'rxjs';

import { TreeDataService } from '@app/shared/service/tree-data.service';
import {
  MAX_CHARACTERS,
  MAX_FRACTIONAL,
  MAX_INTEGER,
  PRODUCT_TYPE_LIST,
  UNIT_TYPE,
  UNIT_TYPE_LIST,
  UNIT_TYPE_STRING_SHORT,
} from '@constants';
import { UtilsService, ValidationErrorsService } from '@core/service';
import { SessionStorage } from '@services/api';
import { InitTreeService } from '@services/catalog';
import { TreeDataAutocompleteService } from '@services/shared';
import { CURRENCY_SHORT, ProductDialogForm, UnitType } from '@typings';

import { AutocompleteOption } from '../autocomplete/autocomplete.model';

@Component({
  selector: 'nm-create-product-dialog',
  templateUrl: './create-product-dialog.component.html',
  styleUrls: ['./create-product-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateProductDialogComponent implements OnInit {
  unitTypeStringShort = UNIT_TYPE_STRING_SHORT;
  productTypeList$ = of(PRODUCT_TYPE_LIST);
  unitTypeList$ = new BehaviorSubject<AutocompleteOption<UnitType>[]>([]);
  currency = CURRENCY_SHORT[this.sessionStorage.getCurrencyUnit()];
  maxCharacters = MAX_CHARACTERS;
  descriptionForUnit: string = 'Редактирование этого поля доступно только до проведения складских операций';

  treeService = new TreeDataAutocompleteService();
  sectionsTreeService: TreeDataService<AutocompleteOption>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { form: FormGroup<ProductDialogForm>; title: string; name: string; close: (confirm: boolean) => void },
    private utilsService: UtilsService,
    private errorService: ValidationErrorsService,
    private initTreeService: InitTreeService,
    public validationErrorsService: ValidationErrorsService,
    private sessionStorage: SessionStorage,
  ) {
    const sectionsTreeService = this.initTreeService.initSections({ preloadAllNodes: true });
    this.treeService.init(sectionsTreeService);
  }

  get controls() {
    return this.data.form.controls;
  }

  get name() {
    return this.controls.name;
  }

  get type() {
    return this.controls.type;
  }

  get section() {
    return this.controls.section;
  }

  get weighable() {
    return this.controls.weighable;
  }

  get unit() {
    return this.controls.unit;
  }

  get quantity() {
    return this.controls.quantity;
  }

  get primePrice() {
    return this.controls.primePrice;
  }

  get salePrice() {
    return this.controls.salePrice;
  }

  get surcharge() {
    return this.controls.surcharge;
  }

  get isSectionError() {
    return this.section.dirty && this.section.invalid;
  }

  get isPrimePriceError() {
    return this.primePrice.dirty && this.primePrice.invalid;
  }

  get isSalePriceError() {
    return this.salePrice.dirty && this.salePrice.invalid;
  }

  get isSurchargeError() {
    return this.surcharge.dirty && this.surcharge.invalid;
  }

  get isQuantityDisabled() {
    return !this.unit.value || this.unit.value === UNIT_TYPE.PIECE;
  }

  get isIngredientOrSemiproduct(): boolean {
    const productTypes = ['INGREDIENT', 'SEMIPRODUCT'];
    return productTypes.includes(this.type.value || '');
  }

  ngOnInit(): void {
    this.updateUnitTypeList();
  }

  getErrorDescription(control: AbstractControl): string {
    if (!control.errors) {
      return '';
    }

    if ('required' in control.errors) {
      return 'Обязательное поле';
    }

    if ('min' in control.errors) {
      return 'Значение меньше одного';
    }

    return '';
  }

  private validateNumber(value: string, fractional: number, maxInteger: number): string {
    return this.utilsService.truncateInteger(
      this.errorService.checkInputNumbersV2(value, {
        decimal: true,
        fractional,
      }),
      maxInteger,
    );
  }

  quantityChange(value: string): void {
    this.quantity.setValue(this.validateNumber(value, MAX_FRACTIONAL.QUANTITY, MAX_INTEGER.QUANTITY));
  }

  primePriceChange(value: string): void {
    this.primePrice.setValue(this.validateNumber(value, MAX_FRACTIONAL.PRIME_PRICE, MAX_INTEGER.PRIME_PRICE));
    this.priceChange();
  }

  salePriceChange(value: string): void {
    this.salePrice.setValue(this.validateNumber(value, MAX_FRACTIONAL.SALE_PRICE, MAX_INTEGER.SALE_PRICE));
    this.priceChange();
  }

  surchargeChange(value: string): void {
    this.surcharge.setValue(this.validateNumber(value, MAX_FRACTIONAL.SURCHARGE, MAX_INTEGER.SURCHARGE));

    const primePrice = Number(this.primePrice.value);
    const surcharge = Number(this.surcharge.value);
    const salePrice = Number(primePrice * (surcharge / 100) + primePrice).toFixed(2);

    this.salePrice.setValue(this.validateNumber(String(salePrice), MAX_FRACTIONAL.SALE_PRICE, MAX_INTEGER.SALE_PRICE));
  }

  priceChange(): void {
    const primePrice = Number(this.primePrice.value);
    const salePrice = Number(this.salePrice.value);
    const surcharge = Number(100 * ((salePrice - primePrice) / primePrice)).toFixed(2);

    this.surcharge.setValue(this.validateNumber(String(surcharge), MAX_FRACTIONAL.SURCHARGE, MAX_INTEGER.SURCHARGE));
  }

  closeForm(confirm: boolean): void {
    this.data.close(confirm);
  }

  updateUnitTypeList() {
    this.unitTypeList$.next(UNIT_TYPE_LIST);
  }

  unitTypeChange(unitType: UnitType): void {
    const currentQuantity = this.quantity.value;

    if (!unitType) {
      this.quantity.setValue('');
    }

    if (unitType === UNIT_TYPE.PIECE || (!currentQuantity && unitType)) {
      this.quantity.setValue('1');
    }
  }

  unitControlHint(control: AbstractControl, errorCode: string): string {
    return this.validationErrorsService.isControlDirtyAndInvalid(control)
      ? this.validationErrorsService.validateField(control) || control.getError(errorCode)
      : this.descriptionForUnit;
  }

  getSuffixForQuantity() {
    return this.unit.value ? this.unitTypeStringShort[this.unit.value] : this.unitTypeStringShort['PIECE'];
  }

  productTypeChange(): void {
    if (this.isIngredientOrSemiproduct) {
      this.quantity.setValue('1');
    }
  }
}
