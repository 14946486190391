import { Injectable, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { CreateSupplierDialogComponent, DeleteArrivalDialogComponent } from '@app/modules/warehouse/components';
import { RevertDialogComponent } from '@app/modules/warehouse/components/revert-dialog/revert-dialog.component';
import { SupplierStorage, WarehouseStorage } from '@app/modules/warehouse/services';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { CreateProductDialogComponent } from '@app/shared/component/create-product-dialog/create-product-dialog.component';
import { DatepickerRange } from '@app/shared/component/datepicker/datepicker.component';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { ModalBaseComponent } from '@app/shared/component/dialog/modal-base/modal-base.component';
import { DateService } from '@app/shared/service/date.service';
import { ExportReportService } from '@app/shared/service/export-report.service';
import {
  DATE_FORMAT,
  DOC_STATUS,
  exportUrlByReportType,
  GET_DOC_STATUS,
  GET_PAYMENT_STATUS_DOCUMENT,
  MAX_CHARACTERS,
  MAX_FRACTIONAL,
  PAYMENT_STATUS_DOCUMENT,
  RootNavigationRoute,
  ROUTE_CREATE_NEW,
  STATUS_DOCUMENT,
  TYPE_SUPPLIER,
  UNIT_TYPE,
  WarehouseRoute,
} from '@constants';
import { SessionStorage } from '@services/api';
import { CatalogStorage, ProductStorage } from '@services/catalog';
import { FormConfirmSaveService, ValidationErrorsService } from '@services/core';
import { NotifyService, SidenavService } from '@services/shared';
import {
  ArrivalDoc,
  ArrivalDocFilter,
  ArrivalDocPage,
  ArrivalForm,
  ArrivalItemForm,
  ArrivalProduct,
  ArrivalProductInput,
  ArrivalProductPage,
  ArrivalsExportFilters,
  CreateArrivalDocInput,
  CreateSupplierInput,
  DateRangeInput,
  DocStatus,
  DocumentNavExtras,
  PageRequestInput,
  ProductCreateInput,
  ProductDialogForm,
  QueryArrivalDocsArgs,
  QueryArrivalProductsArgs,
  QueryResult,
  StockUnit,
  StockUnitInput,
  SupplierUnion,
  TypeSupplier,
  UpdateArrivalDocInput,
} from '@typings';
import { dayjs, getMinValueByFractionalDigitsNumber, setNumberMask } from '@utils';

import { ArrivalsStorage } from './arrivals.storage';

@Injectable({
  providedIn: 'root',
})
export class ArrivalsService {
  maxFractional = MAX_FRACTIONAL;
  exportReportType = exportUrlByReportType;
  arrival: ArrivalDoc;
  arrivalProducts: ArrivalProduct[];
  modalRef: ModalRef<unknown>;
  form: FormGroup<ArrivalForm>;
  refreshSuppliers$ = new BehaviorSubject(true);
  isLoadingConfirmed$ = new BehaviorSubject<boolean>(false);

  isEditing$: Observable<boolean>;
  isConfirmed$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSubmitDisabled$ = new BehaviorSubject(false);
  isSave$ = new BehaviorSubject(false);

  productIndex: number | undefined;
  #productDialogRef: ModalRef<unknown>;
  productForm: FormGroup<ProductDialogForm>;
  isLoading$ = new BehaviorSubject<boolean>(false);

  inputMask = setNumberMask();

  constructor(
    private arrivalsStorage: ArrivalsStorage,
    private productStorage: ProductStorage,
    private exportReportService: ExportReportService,
    private sessionStorage: SessionStorage,
    private router: Router,
    private fb: FormBuilder,
    private formConfirmSaveService: FormConfirmSaveService,
    private modalService: ModalService,
    private sidenavService: SidenavService,
    private suppliersStorage: SupplierStorage,
    private warehousesStorage: WarehouseStorage,
    private dateService: DateService,
    private notifyService: NotifyService,
    private validationErrorsService: ValidationErrorsService,
    private catalogStorage: CatalogStorage,
  ) {}

  openArrivalPage(id?: string, extras?: NavigationExtras): Promise<boolean> {
    this.formConfirmSaveService.closeForms(false);

    return this.router.navigate(
      [this.sessionStorage.getOrgId(), RootNavigationRoute.warehouse, WarehouseRoute.arrivals, id || ROUTE_CREATE_NEW],
      extras,
    );
  }

  back(shouldConfirm: boolean = true) {
    this.sidenavService.back(shouldConfirm);
  }

  showModal(modalTemplate: TemplateRef<ModalBaseComponent>): void {
    this.modalRef = this.modalService.openDialog(modalTemplate);
  }

  showDeleteModal(arrival: ArrivalDoc, callbackFn: () => void): void {
    this.modalRef = this.modalService.openDialog(DeleteArrivalDialogComponent, { data: { arrival, callbackFn } });
  }

  showRevertModal(arrival: ArrivalDoc, callbackFn: () => void): void {
    this.modalRef = this.modalService.openDialog(RevertDialogComponent, {
      data: {
        title: ` поставку №${arrival.number}`,
        callbackFn,
        hideFn: () => {
          this.modalRef.close();
        },
      },
    });
  }

  openCreateSupplier(name: string) {
    this.modalRef = this.modalService.openDialog(CreateSupplierDialogComponent, { data: { name } });
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }

  initForm(arrival: ArrivalDoc, arrivalProducts: ArrivalProduct[], extras?: DocumentNavExtras, shouldRegister: boolean = true): void {
    this.arrival = arrival;
    this.arrivalProducts = arrivalProducts;

    this.form = this.fb.group<ArrivalForm>({
      date: this.fb.nonNullable.control(null, [Validators.required]),
      time: this.fb.nonNullable.control(null, [Validators.required]),
      supplier: this.fb.nonNullable.control(null, [Validators.required]),
      toWarehouseId: this.fb.nonNullable.control(null, [Validators.required]),
      documentItems: this.fb.array(new Array<FormGroup<ArrivalItemForm>>()),
      description: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.DESCRIPTION)]),
      docNumber: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.DOC_NUMBER)]),
      paymentStatus: this.fb.nonNullable.control(PAYMENT_STATUS_DOCUMENT.UNPAID),
    });

    const { controls } = this.form;
    if (this.arrival) {
      const { date, number, supplier, storageRoom, description, paymentStatus } = this.arrival;

      controls.date.setValue(date || null);
      controls.time.setValue(date ? this.dateService.getTime(new Date(date)) : null);

      if (!!supplier) {
        controls.supplier.setValue(
          {
            data: supplier,
            label: this.getSupplierName(supplier.name || ''),
            type: 'item',
            id: supplier.id || '',
          } || null,
        );
      }

      if (!!storageRoom && !storageRoom.archived) {
        controls.toWarehouseId.setValue(
          {
            data: storageRoom,
            label: storageRoom.name || '',
            type: 'item',
            id: storageRoom.id || '',
          } || null,
        );
      }

      controls.description.setValue(description || null);
      controls.docNumber.setValue(number || number == '0' ? number : null);

      if (paymentStatus) {
        controls.paymentStatus.setValue(paymentStatus);
      }

      if (this.arrivalProducts) {
        this.arrivalProducts.forEach((docItem) => {
          const stockUnit = docItem.product || null;
          const quantity = Number(docItem.quantity) || null;
          const count = this.countAmountForOne(Number(docItem.quantity) || null, docItem.sum || null);
          const amountForOne = Number(count) > 0 ? count : '0.01';
          const amount = docItem.sum || null;

          const documentItemForm = this.createArrivalItemFormGroup(stockUnit, quantity, amountForOne, amount, false, docItem.sum || '0');

          controls.documentItems.push(documentItemForm);
        });

        if (this.arrival.status !== 'CONFIRMED') {
          const nullStockUnit = this.createArrivalItemFormGroup();
          controls.documentItems.push(nullStockUnit);
        }
      }

      if (this.arrival.status === 'CONFIRMED') {
        this.form.disable();
        this.form.controls.paymentStatus.enable();
      }
    } else {
      const now = new Date();
      controls.date.setValue(now.toISOString());
      controls.time.setValue(this.dateService.getTime(now));
    }

    (extras?.items || []).forEach((product) => {
      if (!product) {
        return;
      }

      const stockUnit = { id: product.id, name: product.name, unit: product.unit } as StockUnit;
      const documentItemForm = this.createArrivalItemFormGroup(stockUnit, null, null, '0', false);

      controls.documentItems.push(documentItemForm);
    });

    if (extras?.items && !!extras?.items.length) {
      const nullStockUnit = this.createArrivalItemFormGroup();
      controls.documentItems.push(nullStockUnit);
    }

    this.isEditing$ = new BehaviorSubject(!!this.arrival);
    this.isLoadingConfirmed$.next(false);
    this.isConfirmed$.next(this.arrival?.status === 'CONFIRMED');
    this.isSubmitDisabled$.next(this.arrival?.status === 'CONFIRMED');

    if (shouldRegister) {
      this.formConfirmSaveService.setForm(this.form);
    }
  }

  createArrivalItemFormGroup(
    stockUnit: StockUnit | null = null,
    quantity: number | null = null,
    amountForOne: string | null = null,
    amount: string | null = null,
    disabled: boolean = true,
    sum?: string,
  ): FormGroup<ArrivalItemForm> {
    const stockUnitAutocompleteValue: AutocompleteOption<StockUnit> | null = stockUnit
      ? {
          id: stockUnit.id,
          label: stockUnit.name,
          type: 'item',
          data: stockUnit,
        }
      : null;

    const amountValue = this.isConfirmed() ? sum || amount || '0' : amount || '0';

    return this.fb.group<ArrivalItemForm>({
      stockUnit: this.fb.nonNullable.control(stockUnitAutocompleteValue),
      factQuantity: this.fb.nonNullable.control({ value: quantity || 0, disabled }, [
        Validators.required,
        Validators.min(getMinValueByFractionalDigitsNumber(this.maxFractional.QUANTITY)),
      ]),
      amountForOne: this.fb.nonNullable.control({ value: amountForOne || '0', disabled }, [
        Validators.required,
        Validators.min(getMinValueByFractionalDigitsNumber(this.maxFractional.PRIME_PRICE)),
      ]),
      amount: this.fb.nonNullable.control({ value: amountValue || '0', disabled }, [
        Validators.required,
        Validators.min(getMinValueByFractionalDigitsNumber(this.maxFractional.PRIME_PRICE)),
      ]),
    });
  }

  public getSupplierName(name: string): string {
    return name || '';
  }

  createSupplier(name: string, type: TypeSupplier) {
    const supplierCreateInput: CreateSupplierInput = {
      ...(type === TYPE_SUPPLIER.NATURAL_PERSON ? { physical: { name } } : { legal: { name } }),
    };

    this.suppliersStorage.createSupplierV3({ input: supplierCreateInput }).subscribe((res) => {
      this.refreshSuppliers$.next(true);

      const supplier = res.data?.createSupplierV3.supplier!;

      const input: AutocompleteOption<SupplierUnion> = {
        data: supplier,
        label: this.getSupplierName(supplier?.name || ''),
        type: 'item',
        id: supplier?.id || '',
      };

      if (supplier) {
        this.form.controls.supplier.patchValue(input);
      }

      this.hideModal();
    });
  }

  haveArrivalItemsInput(documentItems: FormArray<FormGroup<ArrivalItemForm>>) {
    return documentItems.length > 1;
  }

  private getArrivalItemsInput(documentItems: FormArray<FormGroup<ArrivalItemForm>>): ArrivalProductInput[] {
    return documentItems.controls
      .filter((item) => item.controls.stockUnit.value)
      .map((item) => {
        const { controls } = item;
        const { stockUnit, factQuantity, amount } = controls;
        const input: ArrivalProductInput = {
          sum: amount.value || '0',
          productId: stockUnit.value?.id || '',
          quantity: String(parseFloat(String(factQuantity.value) || '0')),
          unit: stockUnit.value?.data?.unit || UNIT_TYPE.NONE,
        };
        return input;
      })
      .filter((item) => !!item);
  }

  private getArrivalCreateInput(): CreateArrivalDocInput | null {
    const { toWarehouseId, supplier, paymentStatus, date, time, description, documentItems, docNumber } = this.form.controls;
    if (!toWarehouseId.value || !supplier.value) {
      return null;
    }

    return {
      storageRoomId: toWarehouseId.value.id,
      supplierId: supplier.value.id,
      date: this.dateService.getDateTime(date.value, time.value) || '',
      description: description.value,
      paymentStatus: paymentStatus.value,
      products: this.getArrivalItemsInput(documentItems),
      number: docNumber.value || null,
    };
  }

  private getArrivalUpdateInput(): UpdateArrivalDocInput | null {
    if (!this.arrival || !this.arrival.id) {
      return null;
    }

    const { toWarehouseId, supplier, paymentStatus, date, time, description, documentItems, docNumber } = this.form.controls;
    if (!toWarehouseId.value || !supplier.value) {
      return null;
    }

    const addArrivalProducts: ArrivalProductInput[] = [];
    const removeArrivalProducts: string[] = [];
    const documentProducts = this.getArrivalItemsInput(documentItems);

    documentProducts.forEach((d) => {
      if (!this.arrivalProducts.some((prod) => prod.product?.id === d.productId)) {
        addArrivalProducts.push(d);
      }
    });

    this.arrivalProducts.forEach((prod) => {
      if (!documentProducts.some((d) => d.productId === prod.product?.id)) {
        removeArrivalProducts.push(prod.id);
      }
    });

    this.arrivalProducts.forEach((prod) => {
      const docItem = documentProducts.find((d) => d.productId === prod.product?.id)!;
      if (docItem && (docItem.quantity !== String(prod.quantity) || docItem.sum !== String(prod.sum))) {
        removeArrivalProducts.push(prod.id);
        addArrivalProducts.push(docItem);
      }
    });

    return {
      id: this.arrival.id,
      storageRoomId: toWarehouseId.value.id,
      supplierId: supplier.value.id,
      paymentStatus: paymentStatus.value,
      date: this.dateService.getDateTime(date.value, time.value) || '',
      description: description.value,
      addArrivalProducts,
      number: docNumber.value || null,
      removeArrivalProducts,
    };
  }

  isConfirmed(): boolean {
    return this.arrival?.status === 'CONFIRMED';
  }

  async submitForm(): Promise<void> {
    if (!this.haveArrivalItemsInput(this.form.controls.documentItems)) {
      this.notifyService.addNotification({
        type: 'alert',
        title: 'В поставке должны быть указаны позиции',
      });
      return;
    }

    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });

      return;
    }

    this.disableForm();

    if (this.arrival) {
      return this.updateArrival(this.getArrivalUpdateInput());
    } else {
      this.createArrival(this.getArrivalCreateInput());
      return;
    }
  }

  disableForm(): void {
    this.form.disable({ emitEvent: false });
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable({ emitEvent: false });
    this.form.controls.documentItems.controls.forEach((d) => {
      if (!d.controls.stockUnit.value) {
        d.controls.factQuantity.disable();
        d.controls.amount.disable();
        d.controls.amountForOne.disable();
      }
    });
    this.isSubmitDisabled$.next(false);
  }

  getProducts(pageRequest: PageRequestInput, searchText: string, excludeStockUnitIds?: string[]): QueryResult<'products'> {
    return this.productStorage.getProductsShort({
      pageRequest,
      filter: {
        search: searchText,
        excludeStockUnitIds,
        hasTechCard: false,
      },
    });
  }

  getSuppliers(pageRequest: PageRequestInput, searchText: string): QueryResult<'suppliers'> {
    return this.suppliersStorage.getAllSuppliersPageableV2({
      pageRequest,
      filter: {
        search: searchText,
      },
    });
  }

  getWarehouses(pageRequest: PageRequestInput, searchText: string): QueryResult<'storageRooms'> {
    return this.warehousesStorage.storageRooms({
      pageRequest,
      filter: {
        search: searchText,
        states: ['NOT_ARCHIVED'],
      },
    });
  }

  allArrivalsPageable(variables: QueryArrivalDocsArgs): Observable<ArrivalDocPage> {
    return this.arrivalsStorage.arrivalDocs(variables).pipe(map((res) => res?.data?.arrivalDocs || null));
  }

  getArrival(id: string): Observable<ArrivalDoc> {
    return this.arrivalsStorage.arrivalDoc({ id }).pipe(map((res) => res.data.arrivalDoc!));
  }

  async getArrivalWithProducts(id: string): Promise<Observable<ArrivalDoc | ArrivalProduct[]>[]> {
    let allData: ArrivalProduct[] = [];
    let page = 1;
    let totalPages = 1;
    const filter = {
      docId: id,
    };

    while (page <= totalPages) {
      try {
        let data = await this.getArrivalProductsPromise({ filter, pageRequest: { page: page - 1, size: 100 } });
        allData = [...allData, ...data.content];
        if (page === 1) totalPages = data.totalPages;
        page++;
      } catch {
        break;
      }
    }

    return [this.arrivalsStorage.arrivalDoc({ id }).pipe(map((res) => res.data.arrivalDoc!)), of(allData)];
  }

  getArrivalProducts(input: QueryArrivalProductsArgs): Observable<ArrivalProductPage> {
    return this.arrivalsStorage.arrivalProducts(input).pipe(map((res) => res.data.arrivalProducts!));
  }

  getArrivalProductsPromise(input: QueryArrivalProductsArgs): Promise<ArrivalProductPage> {
    return new Promise<ArrivalProductPage>((resolve) => {
      this.getArrivalProducts(input)
        .pipe(take(1))
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  updateArrivalForm(id: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const output = this.getArrivalWithProducts(id);
      output.then((res) => {
        combineLatest([res[0], res[1]])
          .pipe(take(1))
          .subscribe(([arrival, arrivalProducts]) => {
            this.sidenavService.setTopBarTitle(`Поставка №${(arrival as ArrivalDoc).number}`);
            this.formConfirmSaveService.closeForm(false);
            this.initForm(arrival as ArrivalDoc, arrivalProducts as ArrivalProduct[]);
            resolve();
          });
      });
    });
  }

  createArrival(input: CreateArrivalDocInput | null): void {
    if (!input) {
      return;
    }

    this.arrivalsStorage.createArrivalDoc({ input }).subscribe(
      (res) => {
        if (res.data?.createArrivalDoc.result === 'ERROR') {
          this.enableForm();
        } else {
          this.openArrivalPage(res.data?.createArrivalDoc.output?.id);
        }
      },
      () => this.enableForm(),
    );
  }

  async updateArrival(input: UpdateArrivalDocInput | null): Promise<void> {
    if (!input) {
      return;
    }

    return new Promise<void>((resolve) => {
      this.arrivalsStorage.updateArrivalDoc({ input }).subscribe(async (_) => {
        await this.updateArrivalForm(input.id);
        resolve();
        this.enableForm();
      });
    });
  }

  setArrivalDocumentPaymentStatus(): void {
    this.isSave$.next(true);
    if (this.arrival.id) {
      const updateStatus: UpdateArrivalDocInput = {
        id: this.arrival.id,
        paymentStatus: this.form.controls.paymentStatus.value,
        addArrivalProducts: [],
        removeArrivalProducts: [],
      };

      this.arrivalsStorage
        .updateArrivalDoc({ input: updateStatus })
        .pipe(take(1))
        .subscribe(
          () => {
            this.form.controls.paymentStatus.enable();
            this.formConfirmSaveService.closeForm(false);
            this.isSave$.next(false);
          },
          () => {
            this.form.controls.paymentStatus.enable();
            this.isSave$.next(false);
          },
        );
    } else {
      this.form.controls.paymentStatus.enable();
      this.isSave$.next(false);
    }
  }

  deleteArrival(arrival: ArrivalDoc, callbackFn: () => void): void {
    if (!arrival || !arrival.id) {
      return;
    }

    this.modalRef.close();
    this.arrivalsStorage.deleteArrivalDoc({ id: arrival.id }).pipe(take(1)).subscribe(callbackFn);
  }

  closeForm(shouldConfirm: boolean) {
    this.formConfirmSaveService.closeForm(shouldConfirm);
  }

  confirmArrival(arrival: ArrivalDoc): Observable<string | undefined> {
    return this.arrivalsStorage
      .confirmArrivalDoc({
        id: arrival.id,
      })
      .pipe(
        tap(() => {
          this.formConfirmSaveService.closeForm(false);
        }),
        map((res) => (res.data?.confirmArrivalDoc.result === 'SUCCESS' ? arrival.id : '')),
      );
  }

  revertArrival(arrival: ArrivalDoc): Observable<string | undefined> {
    return this.arrivalsStorage
      .revertArrivalDoc({
        id: arrival.id,
      })
      .pipe(
        tap(() => {
          this.formConfirmSaveService.closeForm(false);
        }),
        map((_) => arrival.id),
      );
  }

  exportReport(arrivalId: string, warehouseId: string): void {
    this.exportReportService.exportReportWithHandler(
      this.exportReportType.ARRIVAL.url,
      {
        warehouseId,
        documentId: arrivalId,
        zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      this.exportReportType.ARRIVAL.fileName,
    );
  }

  exportReportAll(params: ArrivalsExportFilters): void {
    const { warehouseIds, supplierIds, statuses, search, dateFrom, dateTo } = params;

    const exportParams: Record<string, string> = {
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (!!search) {
      exportParams.search = search;
    }

    if (dateFrom) {
      exportParams.dateFrom = this.exportReportService.setModifiedDate(dateFrom);
    }

    if (dateTo) {
      exportParams.dateTo = this.exportReportService.setModifiedDate(dateTo);
    }

    if (!!warehouseIds && !!warehouseIds.length) {
      exportParams.warehouseIds = warehouseIds.join(',');
    }

    if (!!supplierIds && !!supplierIds.length) {
      exportParams.supplierIds = supplierIds.join(',');
    }

    if (!!statuses && !!statuses.length) {
      exportParams.statuses = statuses.join(',');
    }

    this.exportReportService.exportReportWithHandler(
      this.exportReportType.ALL_ARRIVAL_V2.url,
      exportParams,
      this.exportReportType.ALL_ARRIVAL_V2.fileName,
    );
  }

  duplicateArrival(arrival: ArrivalDoc | undefined): void {
    if (!arrival || arrival.status !== 'CONFIRMED') {
      return;
    }

    this.arrivalsStorage
      .duplicateArrival({
        documentId: arrival.id as string,
      })
      .subscribe((res) => {
        this.openArrivalPage(res.data?.duplicateArrivalDocument?.output?.id || undefined);
      });
  }

  createDocumentFilterInputV2(options: {
    search: string;
    warehouses: string[];
    statuses: string[];
    paymentStatuses: string[];
    period: DatepickerRange | null;
    suppliers: string[];
  }): ArrivalDocFilter {
    const { search, warehouses, suppliers, paymentStatuses, period, statuses } = options;
    let filter: ArrivalDocFilter = {};

    if (!!search) {
      filter.search = search;
    }

    if (!!warehouses.length) {
      filter.storageRoomIds = Array.from(warehouses);
    }

    if (!!suppliers.length) {
      filter.supplierIds = Array.from(suppliers);
    }

    if (!!statuses.length) {
      if (statuses.find((s) => s === STATUS_DOCUMENT.PENDING)) {
        filter.statuses = [DOC_STATUS.OPEN, DOC_STATUS.FAILURE, DOC_STATUS.PENDING_CONFIRMATION];
      } else {
        filter.statuses = Array.from(statuses).map((status) => GET_DOC_STATUS(status));
      }
    }

    if (!!paymentStatuses.length) {
      filter.paymentStatuses = Array.from(paymentStatuses).map((status) => GET_PAYMENT_STATUS_DOCUMENT(status));
    }

    let range: DatepickerRange;

    if (period && period.start && period.end) {
      range = period;
    } else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      range = new DateRange(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), currentDate);
    }

    const dateRange: DateRangeInput = {
      dateFrom: dayjs(range.start).format(DATE_FORMAT),
      dateTo: dayjs(range.end).format(DATE_FORMAT),
    };

    if (dateRange) {
      filter = Object.assign(filter, { dateRange });
    }

    return filter;
  }

  countAmount(factQuantity: number | null, amountForOne: string | null): string {
    return this.formatNumber((factQuantity || 0) * parseFloat(amountForOne || '0'), false);
  }

  countAmountForOne(factQuantity: number | null, amount: string | null): string {
    return this.formatNumber((Number(amount) || 0) / (factQuantity || 1), true);
  }

  formatNumber(num: number, isFixed: boolean = true): string {
    if (isFixed) {
      return num.toFixed(2).replace(/\.0+$/, '');
    } else {
      return String(Math.floor(num * 100) / 100).replace(/\.0+$/, '');
    }
  }

  initProductForm(name: string) {
    this.productForm = this.fb.group<ProductDialogForm>({
      name: this.fb.nonNullable.control(name, [Validators.required, Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME)]),
      section: this.fb.nonNullable.control(null, [Validators.required]),
      type: this.fb.control(null, [Validators.required]),
      weighable: this.fb.nonNullable.control(false),
      unit: this.fb.nonNullable.control(UNIT_TYPE.PIECE),
      quantity: this.fb.nonNullable.control('1', [Validators.required, Validators.min(0)]),
      primePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      salePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      surcharge: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
    });
  }

  openProductDialog(name: string, index: number): void {
    this.initProductForm(name);
    this.productIndex = index;
    this.#productDialogRef = this.modalService.openDialog(CreateProductDialogComponent, {
      data: {
        form: this.productForm,
        title: 'Новая позиция',
        name,
        close: (confirm: boolean) => this.closeCreateProductDialog(confirm),
      },
      disableClose: true,
    });
  }

  closeCreateProductDialog(confirm: boolean): void {
    if (confirm) {
      if (this.productForm.valid) {
        this.createProduct();
        this.hideModalCreateProduct();
      } else {
        this.validationErrorsService.markFormControls(this.productForm);
      }
    } else {
      this.hideModalCreateProduct();
    }
  }

  hideModalCreateProduct(): void {
    if (!this.#productDialogRef) {
      return;
    }
    this.#productDialogRef.close();
  }

  private createProduct(): void {
    const { name, type, weighable, unit, quantity, section, primePrice, salePrice } = this.productForm.controls;

    const stockUnitInput: StockUnitInput = {
      name: name.value,
      weighable: !weighable.value,
      unit: unit.value || 'NONE',
      quantity: quantity.value,
      primePrice: { amountValue: primePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
      salePrice: { amountValue: salePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
    };

    const productCreateInput: ProductCreateInput = {
      catalogId: this.catalogStorage.getCatalog().id,
      name: name.value,
      type: type.value,
      sectionId: section.value?.id,
      stockUnits: [stockUnitInput],
    };

    this.productStorage.createProduct({ product: productCreateInput }).subscribe((res) => {
      const stockUnits = res.data?.createProductV2?.output?.stockUnits;

      if (this.productIndex !== undefined && stockUnits) {
        const stockUnit = stockUnits[0];
        this.form.controls.documentItems
          .at(this.productIndex)
          .controls.stockUnit.patchValue({ type: 'item', label: stockUnit.name, id: stockUnit.id, data: stockUnit });
      }
    });
  }

  isOpenArrival(status: DocStatus) {
    return status === DOC_STATUS.OPEN || status === DOC_STATUS.FAILURE || status === DOC_STATUS.PENDING_CONFIRMATION;
  }
}
